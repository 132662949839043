import store from '@/store/index.js'

// 获取面包屑导航
function getBreadcrumb(to) {
  const matched = to.matched.map(row => {
    return row.meta.name
  })
  // 一级菜单
  if (matched[0] === undefined) {
    matched.splice(0, 1)
  }
  store.state.breadWord = matched
}

// 获取 表格高度
function getHeihgt(height1) {
  let height = ''
  const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
  height = clientHeight - height1 + 'px'
  store.commit('SET_TABLEHEIGHT', height)
  window.onresize = () => {
    const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
    return (() => {
      height = clientHeight - height1 + 'px'
      store.commit('SET_TABLEHEIGHT', height)
    })()
  }
}

// 格式化 金额字段
const moneyStr = val => {
  if (val || val === 0) {
    return fmoney(val, 2)
  }
}

// 格式化 金额，按照3位数加‘，’方式
function fmoney(s, n) {
  if (s === null || s === '') {
    return '0.00'
  }
  s = parseFloat((s + '')).toFixed(n) + ''
  var b = false
  if (s.toString().indexOf('-') > -1) {
    b = true
    s = s.substring(1, s.length)
  }
  var l = s.split('.')[0].split('').reverse()
  var r = s.split('.')[1]
  let t = ''
  for (var i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? ',' : '')
  }
  if (b) {
    return '-' + t.split('').reverse().join('') + '.' + r
  } else {
    return t.split('').reverse().join('') + '.' + r
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export default {
  moneyStr,
  fmoney,
  parseTime,
  deepClone,
  getHeihgt,
  getBreadcrumb
}
